//Inicialización de los módulos del hs.core

$(document).on('ready', function () {

    //Inicialización de modales
    $.HSCore.components.HSModalWindow.init('[data-modal-target]');
    $.HSCore.components.HSModalWindow.init('.js-autonomous-popup', {autonomous: true});

    //Inicializacion boton flotante ir arriba
    $.HSCore.components.HSGoTo.init('.js-go-to');

    //Inicialización de carruseles
    $.HSCore.components.HSCarousel.init('.js-carousel');

    //Inicialización pestañas
    $.HSCore.components.HSTabs.init('[data-tabs-mobile-type]');

    //Inicializacion del contador
    $.HSCore.components.HSCounter.init('[class*="js-counter"]');

    //Inicializacion de galeria(Fancybox)
    $.HSCore.components.HSPopup.init('.js-fancybox');

    //Iniciacion del focus
    $.HSCore.helpers.HSFocusState.init();

    //Iniciación mascaras de entrada
    $.HSCore.components.HSMaskedInput.init('[data-mask]');

    //Iniciacion sliders
    $.HSCore.components.HSSlider.init('#regularSlider, #regularSlider2, #regularSlider3, #rangeSlider, #rangeSlider2, #rangeSlider3, #stepSlider, #stepSlider2, #stepSlider3');
});


//Pestañas responsive
$(window).on('resize', function () {
    setTimeout(function() {
        $.HSCore.components.HSTabs.init('[data-tabs-mobile-type]');
    }, 200);
});

$(window).on('load', function () {
    //Iniciar el header con megamenu
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');
    $('.js-mega-menu').HSMegaMenu();
});

